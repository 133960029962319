export const changeLocationSubMenuStatus = boolean => {
  return dispatch => {
    dispatch({
      type: 'changeLocationSubMenuStatus',
      boolean: boolean,
    });
  };
};

export const newerCount = number => {
  return {
    type: 'newerCount',
    payload: number,
  };
};

export const selectedIndex = int => {
  return {
    type: 'selectedId',
    payload: int,
  };
};

export const openAlert = boolean => {
  return {
    type: 'openAlert',
    payload: boolean,
  };
};

export const openMessage = boolean => {
  return {
    type: 'openMessage',
    payload: boolean,
  };
};

export const popupMessageText = string => {
  return {
    type: 'popupMessageText',
    payload: string,
  };
};

export const popupMessageOption = ({ type, time }) => {
  return {
    type: 'popupMessageOption',
    payload: { type, time },
  };
};

export const setExpandTree = boolean => {
  return {
    type: 'setExpandTree',
    payload: boolean,
  };
};

export const setReport = boolean => {
  return {
    type: 'setReport',
    payload: boolean,
  };
};

export const setLocationSidebarDrawer = boolean => {
  return {
    type: 'setLocationSidebarDrawer',
    payload: boolean,
  };
};

export const locationSelected = boolean => {
  return {
    type: 'locationSelected',
    payload: boolean,
  };
};

export const showLocationSettings = boolean => {
  return {
    type: 'showLocationSettings',
    payload: boolean,
  };
};

export const insertSelectedLocationID = ID => {
  return {
    type: 'insertSelectedLocationID',
    payload: ID,
  };
};

export const insertDefaultLocationID = ID => {
  return {
    type: 'insertDefaultLocationID',
    payload: ID,
  };
};

export const loading = boolean => {
  return {
    type: 'loading',
    payload: boolean,
  };
};
export const sdsInfoActive = boolean => {
  return {
    type: 'sdsInfoActive',
    payload: boolean,
  };
};

export const reloadAllOurSds = boolean => {
  return {
    type: 'reloadAllOurSds',
    payload: boolean,
  };
};

export const findedData = obj => {
  return {
    type: 'findedData',
    payload: obj,
  };
};

export const setOrganizationFormIsNotSaved = value => {
  return {
    type: 'organizationFormIsNotSaved',
    payload: value,
  };
};

export const setWorkProcedureSubstanceDetails = payload => {
  return {
    type: 'workProcedureSubstanceDetails',
    payload: payload,
  };
};

export const setDisableWorkProcedureEdit = boolean => {
  return {
    type: 'disableWorkProcedureEdit',
    boolean: boolean,
  };
};

export const setOpenEditRiskSDSAmount = boolean => {
  return {
    type: 'openEditRiskSDSAmount',
    boolean: boolean,
  };
};

export const setSelectWorkProcedurePopupType = payload => {
  return {
    type: 'selectWorkProcedurePopupType',
    payload: payload,
  };
};

export const setScrollToHazardBlock = payload => {
  return {
    type: 'scrollToHazardBlock',
    payload: payload,
  };
};

export const setAuthenticating = boolean => {
  return {
    type: 'updateAuthenticating',
    boolean: boolean,
  };
};

export const setChangesNeedToBeSaved = boolean => {
  return {
    type: 'changesNeedToBeSaved',
    boolean: boolean,
  };
};

export const showHeader = boolean => {
  return {
    type: 'showHeader',
    boolean: boolean,
  };
};

export const logout = () => {
  return {
    type: 'logout',
  };
};

export const setSelectedBinderID = ID => {
  return {
    type: 'selectedBinderID',
    payload: ID,
  };
};

export const setDisableRiskAssessmentEdit = boolean => {
  return {
    type: 'disableRiskAssessmentEdit',
    boolean: boolean,
  };
};

export const setSelectedExposure = payload => {
  return {
    type: 'selectedExposure',
    payload: payload,
  };
};
