import { useState, useEffect, memo, Suspense, lazy } from 'react';
import useStyles from './style';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationAsDefault, markAsFavorite } from 'api';
import { checkUserPermissions } from '../../utils/userRoleUtils';
import { deferImport } from 'utils/helper';
import { setOpenDrawer } from 'services/common/slice';
import { loading } from 'store/pageAction/pageAction';
import {
  fetchArchivedLocations,
  fetchLocationStructure,
} from 'services/location/thunk';
import {
  archivedLocationDataSelector,
  commonLocationDataSelector,
  favoriteLocationDataSelector,
} from 'services/location/selectors';
import { setDefaultLocation } from 'services/user/slice';
import { locationIdSelector } from 'services/common/selectors';
import { LocationItem } from 'interfaces/location-management';
import { PERMISSION } from '../../enums/permissions.enums';
import { AppDispatch } from 'constant';
/* Components */
import { ClassNameMap } from '@mui/material';
import CustomTreeView from 'components/location-management/components/location-tree-view/TreeView';
import MyLocationsList from './MyLocationsList';
import LoadingModal from 'components/loader/LoadingModal';

/* Lazy load component */
const CreateLocationPopup = lazy((): Promise<any> => {
  return deferImport(
    import('components/popup/create-location-popup/CreateLocationPopup')
  );
});
/* End */

interface LocationsListProps {
  admin: boolean;
}

const LocationsListV3 = memo(({ admin }: LocationsListProps) => {
  const history = useHistory();
  const location: any = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const classes: ClassNameMap = useStyles();
  const locations = useSelector(commonLocationDataSelector);
  const locationID = useSelector(locationIdSelector);
  const favoriteLocations = useSelector(favoriteLocationDataSelector);
  const archivedLocations = useSelector(archivedLocationDataSelector);
  const [openCreateLocationPopup, setOpenCreateLocationPopup] =
    useState<boolean>(false);
    useState<boolean>(false);
  const [addLocationParentId, setAddLocationParentId] = useState<
    string | number | null
  >(locationID);

  const sortByName = (x: { name: string }, y: { name: string }): number => {
    if (x.name < y.name) return -1;

    if (x.name > y.name) return 1;

    return 0;
  };

  const handleSelect = (selectedLocationId: string | number): void => {
    if (!selectedLocationId) {
      return;
    }
    if (String(selectedLocationId) === String(locationID)) return;
  };

  const setLocationAsDefaultHandler = (
    locationID: string | number,
    callback: ((res: any) => void) | null
  ): void => {
    dispatch(loading(true));
    const setLocationAsDefaultRequest = setLocationAsDefault(locationID);
    setLocationAsDefaultRequest.then((response) => {
      if (response.status === 200) {
        dispatch(loading(false));
        dispatch(setDefaultLocation(locationID));
        if (callback) callback(response);
      }
    });
  };

  const addOrRemoveFromFavorites = (
    locationID: number | string,
    callback: ((res: any) => void) | null
  ): void => {
    markAsFavorite(locationID).then((response) => {
      if (response.status === 200) {
        fetchLocations();
        if (callback) callback(response);
      }
    });
  };

  const fetchLocations = (): void => {
    dispatch(loading(true));
    dispatch(fetchLocationStructure());
  };

  const getArchivedLocations = (): void => {
    dispatch(fetchArchivedLocations());
  };

  useEffect(() => {
    if (archivedLocations && archivedLocations.length > 0) return;
    getArchivedLocations();
  }, []);

  useEffect(() => {
    /**
     * Handle case when user have default location yet:
     * - If user have only 1 location -> set current location
     * - If user click any location from Dashboard page  -> set current location
     */
    if (
      !locationID &&
      (locations.length === 1 || location.state?.selectFirst)
    ) {
      const firstLocation = locations[0];
      if (firstLocation && firstLocation.children.length === 0)
        handleSelect(firstLocation.id);
    }
  }, [locations, favoriteLocations]);

  const sortedLocations = [...locations].sort(sortByName);
  const sortedFavoriteLocations = [...favoriteLocations].sort(sortByName);

  return (
    <div className={classes.locations}>
      <div>
        <div style={{ margin: '0', padding: '0' }}>
          {sortedFavoriteLocations && sortedFavoriteLocations.length > 0 && (
            <>
              <div style={{ marginBottom: 15 }}>
                <MyLocationsList
                  isAdmin={admin}
                  favoriteLocations={sortedFavoriteLocations}
                  selectedLocation={Number(locationID)}
                  handleSelect={(locationID: string) => {
                    if (
                      checkUserPermissions(PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE)
                    ) {
                      dispatch(setOpenDrawer(false));
                      history.push(
                        `/location-management/location-detail/${locationID}`
                      );
                    } else {
                      dispatch(setOpenDrawer(false));
                      history.push(`/my-sds/location-detail/${locationID}`);
                    }
                  }}
                  setLocationAsDefaultHandler={setLocationAsDefaultHandler}
                  handleAddLocation={(locationParentId) => {
                    setAddLocationParentId(locationParentId);
                    setOpenCreateLocationPopup(true);
                  }}
                />
              </div>
            </>
          )}

          <div className={classes.locationList}>
            {locations &&
              sortedLocations.map((item: LocationItem, index: number | any) => {
                return (
                  <CustomTreeView
                    className={classes.locationItem}
                    onSelectLocation={(locationID: string) => {
                      if (
                        checkUserPermissions(
                          PERMISSION.ACCESS_LOCATION_MANAGEMENT_PAGE
                        )
                      ) {
                        dispatch(setOpenDrawer(false));
                        history.push(
                          `/location-management/location-detail/${locationID}`
                        );
                      } else {
                        dispatch(setOpenDrawer(false));
                        history.push(`/my-sds/location-detail/${locationID}`);
                      }
                    }}
                    showCount={true}
                    sortByName={sortByName}
                    key={index}
                    data={item}
                    defaultEndIcon={<div style={{ width: 24 }} />}
                    sx={{
                      height: 264,
                      flexGrow: 1,
                      maxWidth: 400,
                      overflowY: 'auto',
                    }}
                    isAdmin={admin}
                    handleAddLocation={(locationParentId) => {
                      setAddLocationParentId(locationParentId);
                      setOpenCreateLocationPopup(true);
                    }}
                    addOrRemoveFromFavorites={addOrRemoveFromFavorites}
                    selectedLocation={locationID as string}
                    fontSizeContent={13}
                    showFavorites={true}
                  />
                );
              })}
            <Suspense fallback={<LoadingModal />}>
              {openCreateLocationPopup && addLocationParentId && (
                <CreateLocationPopup
                  onClose={() => setOpenCreateLocationPopup(false)}
                  open={openCreateLocationPopup}
                  parentDepartmentID={addLocationParentId as string}
                />
              )}
            </Suspense>
          </div>
        </div>
      </div>
      <div id="chemicals-content" />
    </div>
  );
});

export default LocationsListV3;
